@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Fugaz+One&family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

body {
  --chakra-fonts-body: 'Manrope', sans-serif;
  --chakra-colors-chakra-body-bg: var(--chakra-colors-bg);
  --chakra-colors-chakra-body-text: var(--chakra-colors-white);
  --base-font-size: 16px;
  min-height: 100vh !important;
  transition: none !important;
}

.lose-state,
.spectator-mode {
  background: linear-gradient(180deg, #3a3a3a 0%, #000000 100%);
}

.open-menu {
  overflow: hidden;
  background: black;
}

#root {
  max-width: 400px;
  margin: 0 auto;
}
#root a {
  color: var(--chakra-colors-green);
}

/* .hamburger-react {
  height: 28px;
  width: 28px;
} */
